export default {
  methods: {
    populateCycleComponents(t) {
      return $tpu.r.data.get(t, 'board', 'shared', null, {}).then((data) => {
        const projectStart = data[$tpu.keys.brdCycleStart()]
        const projectDuration = data[$tpu.keys.brdCycleDuration()] || 6

        this._populateCycleFields(projectStart, projectDuration)
        this._populateCycleTimeline(projectStart, projectDuration)

        // This is used for tour
        if (projectStart && projectDuration) {
          this.cycleProvided = true
        }
      })
    },
    _populateCycleFields(projectStart, projectDuration) {
      const startField = GLib.component.findById('cycle_start')
      const durationField = GLib.component.findById('cycle_duration')

      if (startField && durationField) {
        startField.action_merge({
          value: projectStart
        })
        durationField.action_merge({
          value: projectDuration
        })
      }
    },
    _populateCycleTimeline(projectStart, projectDuration) {
      const timelinePanel = GLib.component.findById('cycle_timeline')

      let diffWeeks = -1
      if (projectStart) {
        const oneWeek = 7 * 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffMs = new Date().getTime() - Date.parse(projectStart)
        diffWeeks = Math.round(Math.abs((diffMs) / oneWeek));
      }

      const timelineItems = Array.from(Array(projectDuration)).map((_, i) => {
        if (i == diffWeeks) {
          return {
            text: i + 1,
            backgroundColor: 'blue',
            color: 'white',
            styleClasses: ['small'],
            // fillDot: true
          }
        }
        return {
          icon: i > diffWeeks ? 'radio_button_unchecked' : 'check_circle',
          color: 'blue',
          // backgroundColor: 'transparent',
          backgroundColor: $tpu.styles.secondaryColor(),
          styleClasses: ['x-small']
        }
      })
      timelinePanel.action_merge({
        events: timelineItems
      })
    }
  }
}
